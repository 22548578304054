.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Increased to ensure it's on top of everything */
  background-color: rgba(0, 0, 0, 1); /* Slightly transparent background */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  transition: all 0.3s ease;
}

.logo img {
  padding: 5px;
  height: 60px;
  transition: all 0.3s ease;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-menu li {
  margin: 0 15px;
  position: relative;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  padding-bottom: 5px;
  transition: color 0.3s ease;
}

.nav-menu a::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: red;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.nav-menu a:hover::after,
.nav-menu a.active::after {
  transform: scaleX(1);
}

.nav-menu a:hover,
.nav-menu a.active {
  color: red;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none;
  cursor: pointer;
  width: 30px;
  height: 20px;
  position: relative;
  z-index: 2;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0px;
}

.hamburger span:nth-child(2), .hamburger span:nth-child(3) {
  top: 10px; /* Adjusted to center */
}

.hamburger span:nth-child(4) {
  top: 20px;
}

.hamburger.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
  opacity: 0;
}

.hamburger.open span:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
  opacity: 0;
}

/* Responsive and Mobile Styles */
@media (max-width: 768px) {
  .header {
    padding: 10px 15px;
  }

  p {
    text-align: left;
    float: left;
    width: 100%;
    clear: both;
    padding: 0 15px; /* Add some padding on the sides */
  }

  .logo img {
    height: 50px;
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  .nav-menu.active {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: center;
  }

  .nav-menu li {
    margin: 20px 0;
  }

  .nav-menu a {
    font-size: 18px;
    padding-bottom: 5px; /* Add padding to make space for the underline */
  }

  .nav-menu a::after {
    bottom: 0;
    height: 2px;
  }

  .nav-menu a:hover::after,
  .nav-menu a.active::after {
    transform: scaleX(1);
  }

  .hamburger {
    display: block;
  }
}
