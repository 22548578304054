.residency-container {
    max-width: 1200px;
    margin: 10px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.residency-item {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.residency-item:last-child {
    border-bottom: none;
}

.residency-item h2 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 20px;
}

.residency-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

.residency-artist-name {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: left;
}

.residency-item-text{
    padding-right: 50px;
}

/* Skeleton styles */
.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 20px;
    margin-bottom: 10px;
}

.skeleton-soundcloud {
    width: 100%;
    height: 166px;
}

@media (max-width: 768px) {
    .residency-container {
        padding: 20px;
    }

    .residency-header {
        font-size: 1.5rem;
        margin-bottom: 30px;
    }

    .residency-item h2 {
        font-size: 1.2rem;
        margin-left: 15px;
    }

    .residency-item p {
        font-size: 0.9rem;
        margin-bottom: 50px;
    }

    /* New styles for mobile layout */
    .residency-item {
        display: flex;
        flex-direction: column;
    }

    .residency-item-text {
        order: 1;
    }

    .residency-item-soundcloud {
        order: 2;
    }
}

@media (min-width: 768px) {
    .residency-item {
        flex-direction: row; /* Change to row for desktop layout */
    }

    .residency-item-text {
        flex: 1; /* Allow text to take up one column */
        margin-right: 20px; /* Space between text and SoundCloud embed */
    }

    .residency-item-soundcloud {
        flex: 1; /* Allow SoundCloud embed to take up one column */
    }
}
