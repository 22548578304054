.footer {
    background-color: #4e4e4e;
    color: #fff;
    padding: 20px;
    width: 100%;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.footer-item {
    color: #ffffff;
}

.footer-item p {
    margin: 0;
    color: #ffffff;
    font-weight: 200;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.footer-item p i {
    margin-right: 5px;
    font-size: 0.8rem;
}

.footer-item:last-child p {
    font-weight: 600;
}

.social-icons {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 30px;
}

.social-icon {
    font-size: 1.5rem;
    color: #fff;
}

.fa-envelope {
    font-size: 1rem;
}

/* Ensure any links in the footer are also white */
.footer a {
    color: #fff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
    color: rgb(255, 0, 0);
    transition: color 0.1s ease;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .footer-column {
        align-items: center;
    }

    .footer-item p {
        justify-content: center;
    }
}
