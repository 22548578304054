.events-container {
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Space between event items */
    max-width: 1200px; /* Match the max-width of other containers */
    margin: 20px auto; /* Center the container with margin */
    padding: 40px; /* Keep the padding for spacing */
    background-color: #fff; /* Light background for contrast */
    border-radius: 8px; /* Added border radius for a softer look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
}

.event-item {
    margin: 20px 0; /* Maintain margin for spacing */
    padding: 30px; /* Increased padding for better content spacing */
    width: 100%;
    display: flex; /* Use flexbox for vertical stacking */
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start for consistency */
}

.event-content {
    display: flex; /* Use flexbox for two-column layout */
    flex-direction: column; /* Stack content vertically */
    align-items: flex-start; /* Align items to the start */
}

.event-text {
    flex: 1; /* Allow text to take up available space */
    text-align: left; /* Align text to the left */
}

.event-banner {
    flex: 0 0 auto; /* Allow the banner to take its natural size */
    width: 100%; /* Make the banner full width */
    max-height: 400px; /* Set a maximum height for the banner */
}

.event-banner img {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Match the border radius of the event item */
}

.event-headline {
    font-size: 2rem; /* Increased font size for headlines */
    color: #2c3e50;
    margin-bottom: 50px; /* Adjusted margin for better spacing */
    font-weight: bold; /* Added bold weight for emphasis */
}

.event-description {
    font-size: 1.1rem; /* Slightly larger font for better readability */
    line-height: 1.8; /* Increased line height for improved readability */
    color: #555;
    margin-bottom: 50px; /* Adjusted margin for better spacing */
}

.event-date {
    font-size: 1rem; /* Increased font size for better visibility */
    color: #777;
    margin-top: 10px; /* Added margin for spacing */
    margin-bottom: 50px;
}

.event-date-label {
    font-size: 1rem; /* Match the font size of the event date */
    color: #777; /* Use the same color as the event date for consistency */
    margin-right: 5px; /* Add some space between the label and the date */
}

/* Skeleton styles */
.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 8px; /* Added border radius for skeleton elements */
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 20px;
    margin-bottom: 15px; /* Adjusted margin for better spacing */
}
