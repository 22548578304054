.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.countdown-timer.visible {
  opacity: 1;
}

.countdown-item {
  display: block;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.countdown-number {
  font-size: 10rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #ffffff;
  font-family: Arial, sans-serif;
  width: auto;
  text-align: center;
}

.countdown-label {
  font-size: 0.75rem;
  color: #fff;
  display: none;
}

.colon {
  font-size: 8rem;
  color: #ffffff;
  margin: 0 30px;
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

@media (max-width: 768px) {
  .countdown-timer {
    padding: 10px;
  }

  .countdown-number {
    font-size: 5rem;
    width: 5.5rem;
  }

  .countdown-item {
    margin: 0 5px;
  }

  .colon {
    font-size: 5rem;
    margin: 0 5px;
    padding-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .countdown-timer {
    padding: 5px;
  }

  .countdown-number {
    font-size: 3rem;
    width: 3.5rem;
  }

  .countdown-item {
    margin: 0 10px;
  }

  .colon {
    font-size: 3rem;
    margin: 0 2px;
    padding-bottom: 10px;
  }
}
