.contacts-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Skeleton styles */
.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 1rem;
    margin-bottom: 0.75rem;
    border-radius: 0.25rem;
}

.skeleton-header {
    width: 70%;
    height: 2rem;
    margin-bottom: 1.5rem;
}

.skeleton-map {
    width: 100%;
    height: 350px;
    border-radius: 12px;
}

.contact-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.col-md-6 {
  flex: 1;
  padding: 0 20px;
}

.contact-header {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 40px;
    text-align: left;
    font-weight: 700;
}

.contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.contact-info {
    flex: 1;
    padding: 20px;
}

.contact-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.contact-icon {
    font-size: 1.4rem;
    margin-right: 30px;
    color: #2c3e50;
    flex-shrink: 0;
}

.contact-text {
    flex-grow: 1;
    text-align: left;
}

.contact-text h2 {
    margin-bottom: 5px;
    font-size: 1.3rem;
    color: #2c3e50;
}

.contact-text p {
    margin: 0;
    font-size: 1rem;
    color: #555;
}

.contact-item a {
    color: #555;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-item a:hover {
    color: #b92929;
}

.map-column {
    flex: 1;
    height: 350px;
    padding: 20px;
}

.map-container {
    height: 100%;
    width: 100%;
    flex: 1;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .contact-container {
        padding: 20px;
    }

    .contact-header {
        font-size: 24px;
        text-align: center;
    }

    .contact-items-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .contact-item {
        flex-direction: row;
        align-items: center;
        background-color: #f8f9fa;
        border-radius: 10px;
        padding: 15px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .contact-icon {
        font-size: 24px;
        margin-right: 15px;
    }

    .contact-text h2 {
        font-size: 18px;
        margin: 5px 15px;
    }

    .contact-text p {
        font-size: 16px;
        margin: 0;
    }

    .map-container {
        margin-top: 30px;
    }

    .map-title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 15px;
    }

    iframe {
        height: 300px;
        border-radius: 10px;
    }
}

@media (max-width: 480px) {
    .contact-header {
        font-size: 1.8rem;
        margin-bottom: 50px;
    }

    .map-column {
        height: 300px; /* Adjust this value for smaller screens */
    }

    .skeleton-map {
        height: 300px;
    }

}

.email-container {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}
