/* Styling for the video banner */
.video-banner {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.banner-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%) scale(1);
  object-fit: cover;
  opacity: 30%;
  z-index: -1;
}

.page-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.page-title h2 {
  font-size: 4rem;
  font-weight: 100;
  letter-spacing: 1.5px;
  margin: 0;               /* Remove any default margin that could cause shifts */
  white-space: nowrap;     /* Prevent text from wrapping to a new line */
}

.event-title {
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center; /* Center the content horizontally */
}

.event-title h2 {
  letter-spacing: 1.5px;
  margin: 0;               /* Remove any default margin that could cause shifts */
  white-space: nowrap;     /* Prevent text from wrapping to a new line */
  font-weight: 300;
}

/* Event button styles */
.video-banner .event-button {
  border: 2px solid #ffffff;
  color: #ffffff;
  background-color: transparent;
  padding: 10px 20px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  margin-top: 65px !important; /* Added margin-top with !important */
}

.video-banner .event-button:hover {
  background-color: transparent; /* Changed from 'none' to 'transparent' */
  color: #ff0000;
  border-color: #ff0000;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .video-banner {
    height: 80vh; /* Full viewport height on mobile */
  }

  .banner-video {
    transform: translate(-50%, -50%) scale(1.2); /* Slightly larger scale for better coverage */
  }

  .event-title h2 {
    font-size: 1.2rem;
    text-align: center;
    padding: 0 10px;
  }

  .video-banner .event-button {
    margin-top: 50px !important; /* Added !important */
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Additional breakpoint for very small screens */
@media (max-width: 480px) {
  .video-banner {
    height: 95vh;
  }

  .event-title h2 {
    font-size: 1rem;
  }

  .video-banner .event-button {
    margin-top: 40px !important; /* Adjusted margin-top with !important */
    font-size: 14px;
    padding: 6px 12px;
  }
  
}