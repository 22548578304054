.home-container {
  max-width: 1200px; /* This limits the width of the container */
  margin: 10px auto; /* Center the container */
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.home-header {
  font-size: 1.5rem; /* Updated to match Residency.css */
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: left;
}

p {
  font-size: 1rem; /* This remains unchanged */
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
  text-align: left;
}

.carousel-item {
  height: 300px; /* Keep fixed height for desktop */
  min-width: 100%;
  overflow: hidden;
  background-color: #000;
  transition: transform 0.5s ease-in-out;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure images cover the container */
  transition: opacity 0.5s ease-in-out;
}

.carousel-indicators button {
  background-color: #303030;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.carousel-indicators .active {
  background-color: #ffffff;
  opacity: 1;
}

@media (max-width: 768px) {
  .content-wrapper {
    display: flex !important; /* Use flexbox for layout */
    flex-direction: column !important; /* Stack items vertically */
  }

  .text-section, .carousel-section {
    width: 100% !important; /* Ensure full width for mobile */
    margin-bottom: 20px !important; /* Add spacing between sections */
  }

  /* Optional: Adjust the height of the carousel for mobile */
  .carousel-item {
    height: 300px !important; /* Set a fixed height for mobile */
  }

  .home-container {
    padding: 20px !important; /* Adjusted padding for mobile */
  }

  .home-header{
    margin: 40px 12px;
  }
}

@media (max-width: 480px) {
  .home-header {
    font-size: 1.5rem; /* Updated to match Residency.css */
    margin: 0px 0px 20px 15px;
  }

  p {
    font-size: 0.9rem; /* Updated to match Residency.css */
  }

  .text-section{
    margin: 0 !important;
    padding: 0 !important;
  }

  .carousel-section{
    padding: 0 !important;
  }
}

.event-title {
  text-align: center;
  margin-top: 20px;
}

.event-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 100%);
  }
  100% {
    background-color: hsl(0, 0%, 100%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-header {
  width: 50%;
  height: 1.5rem;
  margin-bottom: 1rem;
}

.skeleton-carousel {
  width: 100%;
  height: 300px;
  border-radius: 0.25rem;
}

.content-wrapper {
  display: flex; /* Use flexbox for layout */
  flex-direction: row; /* Default to row for desktop */
  gap: 20px; /* Adds space between sections */
  width: 100%; /* Ensure full width */
}

.text-section,
.carousel-section {
  width: 100%; /* Full width for each section */
}
