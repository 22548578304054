@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

@media (max-width: 768px) {
  /* Keep other responsive styles for the video banner and countdown timer */
  /* ... */
}

/* Keep additional breakpoint for very small screens if needed */
@media (max-width: 480px) {
  /* ... */
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}